<template>
	<div v-if="canShare">
		<v-flex>
			<w-btn flat icon="mdi-link-variant" mini @click="doShowShareDialog()">{{ $t('document.share.actions.share') }}</w-btn>
		</v-flex>
		<ShareDialog v-if="showShareDialog" v-model="node" @close="closeDialog()" />
	</div>
</template>

<script>
import { mapState } from 'vuex'
import DocumentShareModuleGuard from '@/mixins/ModulesGuards/Documents/DocumentShareModuleGuard'

export default {
	name: 'ShareBtn',
	components: {
		ShareDialog: () => ({
			component: import('@/components/Documents/Share/ShareDialog')
		})
	},
	mixins: [DocumentShareModuleGuard],
	props: {
		value: {
			required: true,
			type: Array
		}
	},
	data: function () {
		return {
			showShareDialog: false
		}
	},
	computed: {
		...mapState({
			isGuest: state => state.company.userRoles.isGuest
		}),
		model: {
			get: function () {
				return this.value
			},
			set: function (value) {
				this.$emit('input', value)
			}
		},
		node: {
			get: function () {
				return this.value.length == 1 ? this.value[0] : null
			},
			set: function (value) {
				this.model = value
			}
		},
		canShare: function () {
			return this.node && !this.isGuest && !this.node.is_trash && !this.node.is_folder
		}
	},
	methods: {
		doShowShareDialog: function () {
			if (this.canShare) {
				this.showShareDialog = true
			}
		},
		closeDialog: function () {
			this.showShareDialog = false
		}
	}
}
</script>
