// ============================================================================
// DocumentsShareApi
// -------------------
// Document share api
// ============================================================================
// -------
// Imports
// -------
import { Backend } from '@/plugins/axios/Configurator'
import URLS from '@/apis/BackEndAPIEndpoints'

import { RequiredArguments } from '@/helpers/methods'

// -------
// Exports
// -------
export default {
	vendor: {
		document: {
			create: function (vendorId, documentId, params = null, data = null, doCancelPreviousRequest = false, doCancelAllOtherRequests = false) {
				RequiredArguments({ vendorId: vendorId, documentId: documentId })
				return Backend.POST(URLS.api.vendors.documents.shares.uri, [vendorId, documentId], params, data, doCancelPreviousRequest, doCancelAllOtherRequests)
			}
		}
	}
}
