// ============================================================================
// DocumentShareService
// --------------------
// DocumentShare module related services
// ============================================================================

// -------
// Imports
// -------
import { NotImplementedYet, RequiredArguments } from '@/helpers/methods'

import API from '@/apis/DocumentShareApi'
// ---------
// Internals
// ---------
const Private = {
	requests: {
		vendor: {
			document: {
				create: function (vendorId, document, params = null, data = null) {
					RequiredArguments({ vendorId: vendorId, document: document })
					return API.vendor.document.create(vendorId, document.id, params, data)
				},
				read: NotImplementedYet,
				update: NotImplementedYet
			}
		}
	},
	service: {
		vendor: {
			document: {
				create: function (vendorId, document, params = null, data = null) {
					RequiredArguments({ vendorId: vendorId, document: document })
					return Private.requests.vendor.document.create(vendorId, document, params, data)
				},
				read: NotImplementedYet,
				update: NotImplementedYet
			}
		}
	}
}

// -------
// Exports
// -------
export default {
	createDocumentShare: Private.service.vendor.document.create
}
